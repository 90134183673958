import React from 'react'

import MainLayout from '../components/layouts/MainLayout'
import Footer from '../components/footer'
import SEO from '../components/seo'

import './save-the-date.css'

function SaveTheDate() {
  return (
    <MainLayout activeMenu="home">
      <SEO title="Accueil" />
      <div className="save-the-date-container">
        <div className="save-the-date-content">
          Save the date!
          <br />
          06 Aout 2022
          <br />
          La demeure du pont - Le Pont Benet, 35320 La Couyère
        </div>
        <Footer />
      </div>
    </MainLayout>
  )
}

export default SaveTheDate
